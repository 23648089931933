import React from "react";
//Animations
import { motion } from "framer-motion";
import { pageAnimation, titleAnim } from "../animation";
import styled from "styled-components";



const ContactUs = () => {
  return (
    <ContactStyle
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      style={{ background: "#282828" }}
    >
      <Title>
        <Hide>
          <motion.h2 variants={titleAnim}>Contact Destani.</motion.h2>
        </Hide>
      </Title>
      <div>
        <Hide>
          <Social variants={titleAnim}>
            <Circle />
            <h4>&nbsp;&nbsp;Send Destani a Message</h4>
            <p></p>
          </Social>
        </Hide>
        <Hide>
          <Social variants={titleAnim}>
            <Circle />
            <h4>&nbsp;&nbsp;Send an Email:&nbsp;</h4>
            <p>
              <Link a href="mailto:destani@destani.dev">destani@destani.dev</Link>
            </p>
          </Social>
        </Hide>
        <Hide>
          <Social variants={titleAnim}>
            <Circle />
            <h4>&nbsp;&nbsp;LinkedIn:&nbsp;</h4>
            <p>
            <Link a href="https://linkedin.com/in/destani">
                linkedin.com/in/destani
              </Link>
            </p>
          </Social>
        </Hide>
        <Hide>
          <Social variants={titleAnim}>
            <Circle />
            <h4>&nbsp;&nbsp;GitHub:&nbsp;</h4>
            <p>
            <Link a href="https://github.com/destanidev">github.com/destanidev</Link>
            </p>
          </Social>
        </Hide>
      </div>
    </ContactStyle>
  );
};

const ContactStyle = styled(motion.div)`
  padding: 5rem 10rem;
  color: white;
  min-height: 90vh;
  @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
  }
`;

const Link = styled.a`
  color: #DDEBF8;
  font-size: 2rem;

}`;

const Title = styled.div`
  margin-bottom: 4rem;
  color: white;
  @media (max-width: 1500px) {
    margin-top: 5rem;
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;
const Circle = styled.div`
  border-radius: 35%;
  width: 1rem;
  height: 1rem;
  background: #ddebf8;
`;
const Social = styled(motion.div)`
  display: flex;
  align-items: center;
 h2 {
    margin: 2rem;
  }
`;

export default ContactUs;
