import React from "react";
import styled from "styled-components";
import { About } from "../styles";
import Toggle from "./Toggle";
import { AnimateSharedLayout } from "framer-motion";
import { useScroll } from "./useScroll";
import { scrollReveal } from "../animation";

const SkillSection = () => {
  const [element, controls] = useScroll();
  return (
    <Skills
      variants={scrollReveal}
      ref={element}
      animate={controls}
      initial="hidden"
    >
      <h2>
        <span>Skills</span>
      </h2>
      <AnimateSharedLayout>
        <Toggle title="Front-End Development">
          <div className="answer">
            <p>HTML, CSS, JS, ReactJS, React Native, Bootstrap</p>

            <p>
              Figma, Version Control/Git, Responsive Design, Testing/Debugging,
              Accessibility
            </p>

            <p>
              Search Engine Optimization, Terminal/Command Line, API Services,
              Webflow
            </p>
          </div>
        </Toggle>
        <Toggle title="Back-End Development">
          <div className="answer">
            <p>
              MongoDB, ExpressJS, NodeJS, PostgreSQL, MySQL, SQL, Ruby, GIS Web
              Development, Database Admin
            </p>
          </div>
        </Toggle>
        <Toggle title="Adobe Creative Cloud">
          <div className="answer">
            <p>Photoshop, Illustrator, InDesign, Premiere, XD, Acrobat</p>
          </div>
        </Toggle>
        <Toggle title="Design">
          <div className="answer">
            <p>
              Branding, Social Media, Layout and Graphic Design, UI/UX,
              Accessibility
            </p>
          </div>
        </Toggle>
        <Toggle title="IT">
          <div className="answer">
            <p>Software Lifecycle Project Management</p>
            <p>IT Project and Service Management</p>
            <p>Networking and Hardware</p>
            <p>Systems Engineering & Cybersecurity</p>
            <p>Agile and Scrum</p>
    
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </Skills>
  );
};

const Skills = styled(About)`
  display: block;
  span {
    display: block;
  }
  h2 {
    padding-bottom: 2rem;
    font-weight: lighter;
  }
  .skill-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 3rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
  }
`;

export default SkillSection;
