//Import Images
import wavesplayer from "./img/waves.png";
import wavesplayer2 from "./img/waves2.png";
import caribouwebflow from "./img/caribouwebflow.png";
import cariboudevform from "./img/cariboudevform.png";
import lash1 from "./img/lash1.png";
import lash2 from "./img/lash2.png";

export const WorkState = () => {
  return [
    {
      title: "Second Lash Site",
      mainImg: lash1,
      url: "/work/lash",
      secondaryImg: lash2,
    },
    {
      title: "React Music Player",
      mainImg: wavesplayer,
      url: "/work/wavesplayer",
      secondaryImg: wavesplayer2,
    },
    { 
      title: "Web Flow Portfolio",
      mainImg: caribouwebflow,
      url: "/work/webflow",
      secondaryImg: cariboudevform,

        },
  ];
};