import React from "react";

import home1 from "../img/home1.jpg";
import { About, Description, Image, Hide } from "../styles";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import Wave from "./Wave";

const AboutSection = () => {
  return (
    <About>
      <Description>
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>
              <span>Destani</span>
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>Full Stack Web Development</motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}><span>@ Caribou Dev</span></motion.h2>
          </Hide>
        </motion.div>
        <motion.p variants={fade}>
          Full Stack Web Developer specializing in React, JavaScript, <br></br>UI/UX & Design, Back-End, Servers Admin, & Testing.
        </motion.p>
        <a href="mailto:destani@destani.dev"><motion.button variants={fade}>Contact Me</motion.button></a>
      </Description>
      <Image>
        <motion.img variants={photoAnim} src={home1} alt="caribou" />
      </Image>
      <Wave />
    </About>
  );
};

//Styled Components

export default AboutSection;
